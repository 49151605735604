import {Reducer} from "redux"
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ClinicSettingsState} from "./clinicSettings.types"
import i18next  from "i18next"

const lang = i18next.language

const initialState: ClinicSettingsState = {
  clinicSettings: {
    id: "",
    domain: "",
    name: "",
    couponInRegister: true,
    requireCoupon: false,
    collectMedicalDocument: false,
    clinicSignalIdunaSettings: {
      insuranceVerificationEnabled: false
    },
    frontendSettings: {
      dashboardUrl: "",
      clinicId: "",
      style: {
        mainColor: "#20A869",
        hoverColor: "#0E7E4A",
        lightBgColor: "",
        gradientColor: "",
        chatBgColor: "",
        buttonBorderRadius: 0,
        dynamicStylesEnabled: false
      },
      images: {
        logo: "",
        favicon: ""
      },
      seo: {
        title: "",
      },
      modules: []
    },
    enabledLanguages: {
      languages: [],
    },
    widgetRegisterSettings: {
      linkToRegulations: `https://telemedi.com/${lang}/terms-and-conditions/`,
      hidePeselOption: false,
    },
  },
}

const clinicSettingsSlice = createSlice({
  name: "clinicSettings",
  initialState,
  reducers: {
    setClinicSettings: (state, action: PayloadAction<ClinicSettingsState["clinicSettings"]>) => {
      state.clinicSettings = action.payload
    },
  },
})

export const {
  setClinicSettings,
} = clinicSettingsSlice.actions

export default clinicSettingsSlice.reducer as Reducer<typeof initialState>
