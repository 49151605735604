import React, { FC, ReactNode } from "react"
import { useSelector } from "react-redux"
import { UseFormRegister } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  StyledLinkRegisterBox,
  StyledPhoneSection,
  StyledRow,
  StyledRowSelectInput,
  StyledSslInfo,
} from "../RegisterForm.styles"
import Input from "../common/input/Input.component"
import PrefixCode from "../common/prefixCode/PrefixCode.component"
import Select from "../common/select/Select.component"
import VoucherSection from "../voucherSection/VoucherSection.component"
import CheckBox from "../common/checkBox/CheckBox.component"
import { selectClinicSettings } from "../../../store/clinicSettings/clinicSettings.selectors"
import { getRegisterFormLabels, getRegisterFormLinks, signalIdunaInsuranceCardTypes } from "../../../constants/constants"
import ssl from "../../../assets/icons/ssl.svg"
import { RegisterFormData } from "../RegisterForm.types"

interface RegisterFormStep2Props {
  errors?: ReactNode;
  register: UseFormRegister<RegisterFormData>;
  getValues: (payload?: string | string[]) => Record<string, string[]>;
  setValue(name: string, value: string): void;
  handleOnChangeNationality?(event: React.ChangeEvent<HTMLSelectElement>): void;
  handleChangeBirthDate?(event: React.ChangeEvent<HTMLInputElement>): void;
  countriesList: JSX.Element | JSX.Element[];
  changeDocumentType?(event: React.ChangeEvent<HTMLSelectElement>): void;
  documentTypeOptions: JSX.Element | JSX.Element[];
  documentFieldName: string;
  idNumberFieldDisabled: boolean;
  documentTypeListVisible: boolean;
  noDocumentTypeOption: JSX.Element | JSX.Element[];
  documentValueVisibility: boolean;
  selectedCountryName?: string;
  setSelectedCountryName?: React.Dispatch<React.SetStateAction<string>>;
  setPrefixCodeFlagSrc: React.Dispatch<React.SetStateAction<string>>;
  detectedCountry: string;
  prefixCodeFlagSrc?: string;
  control: any;
}

const RegisterFormStep2: FC<RegisterFormStep2Props> = (
  {
    register,
    errors,
    handleOnChangeNationality,
    countriesList,
    changeDocumentType,
    documentTypeOptions,
    documentFieldName,
    idNumberFieldDisabled,
    documentTypeListVisible,
    noDocumentTypeOption,
    documentValueVisibility,
    selectedCountryName,
    detectedCountry,
    prefixCodeFlagSrc,
    setPrefixCodeFlagSrc,
    handleChangeBirthDate,
    control,
    setValue
  }
) => {
  const registerFormLabels = getRegisterFormLabels()
  const registerFormLinks = getRegisterFormLinks()
  const { t } = useTranslation()
  const customFieldRequiredLabel = documentTypeListVisible ? "*" : "" // not required when pesel is needed / nationality is PL
  const customFieldsLabels = {
    sip: `${t("defaultTranslations:registerForm:sip")}${customFieldRequiredLabel}`,
    siz: `${t("defaultTranslations:registerForm:siz")}${customFieldRequiredLabel}`,
    insuranceNumber: `${t("defaultTranslations:registerForm:patientCardNumber")}${customFieldRequiredLabel}`
  }
  const {
    couponInRegister,
    widgetRegisterSettings,
    clinicSignalIdunaSettings,
    collectMedicalDocument,
    frontendSettings: { modules }
  } = useSelector(selectClinicSettings)

  const isMarketingAgreeDisabled = modules.includes("remove_marketing_consent_sections_on_patient_registration")
  const isDifferentDateFormatEnabled = modules.includes("enable_different_date_format")

  const clausesCheckboxLabel = (
    <>
      { registerFormLabels.rulesAccept }
      <StyledLinkRegisterBox rel="noopener noreferrer" href={widgetRegisterSettings.linkToRegulations || registerFormLinks.rules} target="_blank">
        { registerFormLabels.rulesLink }
      </StyledLinkRegisterBox>
      { registerFormLabels.andLabel }
      <StyledLinkRegisterBox rel="noopener noreferrer" href={registerFormLinks.privacyPolicy} target="_blank">
        { registerFormLabels.privacyPolicy }
      </StyledLinkRegisterBox>*
    </>
  )

  return (
    <>
      <StyledRow>
        <Input
          register={register}
          name="firstName"
          errors={errors}
          placeholder={t("defaultTranslations:registerForm:placeholder:name")}
        />
      </StyledRow>
      <StyledRow>
        <Input
          register={register}
          name="lastName"
          errors={errors}
          placeholder={t("defaultTranslations:registerForm:placeholder:surname")}
        />
      </StyledRow>
      <StyledRow>
        <Input
          register={register}
          name="birthDate"
          errors={errors}
          onChange={handleChangeBirthDate}
          placeholder={
            t(
                "defaultTranslations:registerForm:placeholder:" + (
                    isDifferentDateFormatEnabled
                        ? "differentFormatBirthDate"
                        : "birthDate"
                )
            )
          }
          mask={isDifferentDateFormatEnabled ? "99-99-9999" : "9999-99-99"}
        />
      </StyledRow>
      <StyledPhoneSection>
        <PrefixCode
          register={register}
          name="prefixCode"
          errors={errors}
          setValue={setValue}
          selectedCountryName={selectedCountryName}
          prefixCodeFlagSrc={prefixCodeFlagSrc}
          setPrefixCodeFlagSrc={setPrefixCodeFlagSrc}
          control={control}
        />
        <div>
          <Input
            register={register}
            name="phoneNumber"
            errors={errors}
            placeholder={t("defaultTranslations:registerForm:placeholder:phone")}
          />
        </div>
      </StyledPhoneSection>
      {
        <StyledRow>
          <Select
            name="nationality"
            register={register}
            defaultValue={detectedCountry}
            onChange={handleOnChangeNationality}
          >
            { countriesList }
          </Select>
        </StyledRow>
      }
      {
        documentTypeListVisible && (
          <StyledRow>
            <Select
              id="nationality"
              name="identityDocumentType"
              onChange={changeDocumentType}
              errors={errors}
              register={register}
              defaultValue=""
            >
              { documentTypeOptions }
              { noDocumentTypeOption }
            </Select>
          </StyledRow>
        )
      }
      {
        documentValueVisibility && (
          <StyledRow>
            <Input
              register={register}
              name={documentFieldName}
              disabled={idNumberFieldDisabled}
              errors={errors}
              placeholder={t(`defaultTranslations:registerForm:placeholder:${documentFieldName === "identityNumber" ? "idNumber" : (documentFieldName === "pesel" ? "pesel" : "passport")}`)}
            />
          </StyledRow>
        )
      }
      {
        clinicSignalIdunaSettings?.insuranceVerificationEnabled && (
          <StyledRow>
            <StyledRowSelectInput>
              <Select
                name="insuranceNumberPrefix"
                errors={errors}
                register={register}
                defaultValue={signalIdunaInsuranceCardTypes.SIP}
              >
                <option value={signalIdunaInsuranceCardTypes.SIP}>{customFieldsLabels.sip}</option>
                <option value={signalIdunaInsuranceCardTypes.SIZ}>{customFieldsLabels.siz}</option>
              </Select>
              <Input
                register={register}
                name="insuranceNumber"
                errors={errors}
                placeholder={customFieldsLabels.insuranceNumber}
                mask="999999/99999"
              />
            </StyledRowSelectInput>
          </StyledRow>
        )
      }
      {
        couponInRegister && (
          <StyledRow>
            <VoucherSection>
              <Input
                register={register}
                name="voucher"
                errors={errors}
                placeholder={t("defaultTranslations:registerForm:placeholder:voucherCode")}
              />
            </VoucherSection>
          </StyledRow>
        )
      }
      { !isMarketingAgreeDisabled && (
        <StyledRow>
          <CheckBox
            register={register}
            name="mailingAgree"
            errors={errors}
            label={registerFormLabels.newsletterClause}
          />
        </StyledRow>
      )}
      <StyledRow>
        <CheckBox
          register={register}
          name="termsAccepted"
          errors={errors}
          validation={{
            required: t("validation.checkRules"),
          }}
          label={clausesCheckboxLabel}
        />
      </StyledRow>
        {collectMedicalDocument && (
            <StyledRow>
                <CheckBox
                    register={register}
                    name="collectMedicalDocument"
                    errors={errors}
                    validation={{
                        required: t("validation.checkRules"),
                    }}
                    label={`${t("defaultTranslations:documentationAvailability")}*`}
                />
            </StyledRow>
        )}
      <StyledSslInfo>
        <div>
          <img src={ssl} alt="" />
        </div>
        <div>
          { t("defaultTranslations:registerForm:sslInfo") }
        </div>
      </StyledSslInfo>
    </>
  )
}

export default RegisterFormStep2
